<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="520"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="佣金转存余额"
      :body-style="{ color: 'red' }"
      @ok="handleSubmit"
    >
      <a-form
        class="iotplt-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item
          label="佣金余额(元)"
        >
          <a-input
            disabled
            v-model="this.commissionAvailable"
          />
        </a-form-item>

        <a-form-item
          label="转存金额(元)"
        >
          <a-spin :spinning="loadingCommission">
            <a-input-number
              style="width: 100%"
              :min="0.01"
              :max="commissionAvailable"
              :step="0.01"
              :precision="2"
              v-decorator="['transfer_amount', {
                rules: [
                  { required: true, message: '请输入金额' }
                ]
              }]"
            />
          </a-spin>
        </a-form-item>

        <a-form-item label="提醒" style="color:red">
          佣金转存到余额金额不支持转回、提现。
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import { findAgentFinanceOverview, transferAgentCommission } from '@/api/agent'

export default {
  name: 'NewTransfer',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      commissionAvailable: 0,
      form: this.$form.createForm(this, { name: 'new_transfer' }),
      submitting: false,
      loadingCommission: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    agentId() {
      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchCommissionStatistic()
  },
  methods: {
    fetchCommissionStatistic() {
      this.loadingCommission = true
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.commissionAvailable = res.data.commission_available
        }
        this.loadingCommission = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          this.$set(values, 'agent_id', this.agentId)
          transferAgentCommission(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
